import { CabButton } from "@CabComponents/CabButton";
import { Box, styled, Typography } from "@mui/material";
import { ReactNode } from "react";
import colors from "../../colors";
import UpgradeOverlay from '../../components/Common/UpgradeOverlay/UpgradeOverlay';
import CabSpinner from "@CabComponents/CabSpinner";


export interface UpdatedWidgetProps {
  title: string;
  buttonName?: string;
  buttonOnClick?: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  emptyMessage?: string;
  children: ReactNode;
  accessBlocked?: boolean;
  upgradeMessage?: string;
  allLoaded: boolean;
  borderColorOverride?: string;
}

const WidgetContainer = styled(Box, {label: "WidgetContainer"})({
  border: '1px solid',
  backgroundColor: colors.white900,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  borderRadius: 6,
  padding: '16px 0px 16px 16px',
  width: '100%',
  height: '100%',
});

const HeaderBox = styled(Box, {label: "HeaderBox"})({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: 16,
  width: '100%',
  alignItems: 'center'
});

const ContentBox = styled(Box, {label: "ContentBox"})({
  backgroundColor: colors.black200,
  overflow: 'auto',
  marginTop: 24,
  overflowX: 'hidden',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
    backgroundColor: colors.white900,
  },
  '::-webkit-scrollbar-thumb': {
    background: colors.black300,
    borderRadius: 4,
  }
});

const UpdatedDashboardWidget = ({
  title, buttonName, buttonOnClick, isLoading, emptyMessage, children, isEmpty, accessBlocked, upgradeMessage,
  allLoaded, borderColorOverride
}:
UpdatedWidgetProps): ReactNode => {
  return (
    <WidgetContainer sx={{borderColor: borderColorOverride ?? colors.black400}}>
      <HeaderBox>
        <Typography variant='h4'>{title}</Typography>
        {buttonName && buttonOnClick && (
          <CabButton buttonType='text' sx={{ fontSize: '14px', color: colors.lavendar800 }} onClick={buttonOnClick}>
            {buttonName}
          </CabButton>
        )}
      </HeaderBox>
      <ContentBox>

        {allLoaded && children}
      </ContentBox>
      {(isEmpty && allLoaded) && (
        <Box height='100%' display='flex' justifyContent='center'>
          <Box display='flex' alignItems='center'>
            <Typography variant='body1' padding='10px' textAlign='center'>{emptyMessage}</Typography>
          </Box>
        </Box>
      )}
      { (isLoading || !allLoaded) && (
        <Box height='100%' display='flex' alignItems='center'>
          <CabSpinner scale={4}/>
        </Box>
      )}
      {
        accessBlocked && (
          <UpgradeOverlay
            show={accessBlocked}
            message={upgradeMessage} />
        )
      }
    </WidgetContainer>
  );
};

export default UpdatedDashboardWidget;